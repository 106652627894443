import {inject, Injectable} from '@angular/core';
import {ConstantsService} from "./constants.service";

const globalConstants = ['item_height', 'item_width', 'item_length'];

export interface HighlightedRanges {
  constantRanges: Range[];
  globalConstantRanges: Range[];
}

export interface Matches {
  constantMatches: string[];
  globalConstantMatches: string[];
}

@Injectable()
export class HighlightingService {
  ranges: Map<string, HighlightedRanges> = new Map();
  constantsService = inject(ConstantsService);

  getMatches(textContent: string, mainProcess: string): Matches {
    const regex = /\$([a-zA-Z_]\w*)/g;
    let match: RegExpExecArray | null;
    let constantMatches: string[] = [];
    let globalConstantMatches: string[] = [];
    while ((match = regex.exec(textContent)) !== null) {
      if (globalConstants.includes(match[0].replace('$', ''))) {
        globalConstantMatches.push(match[0]);
      } else if (this.constantsService.constants().filter(e => e.mainprocess == mainProcess).some(e => e.name == match![0].replace('$', ''))) {
        constantMatches.push(match[0]);
      }
    }
    return {constantMatches: constantMatches, globalConstantMatches: globalConstantMatches};
  }

  setRanges(textId: string, ranges: HighlightedRanges) {
    this.ranges.set(textId, ranges);
    this._updateHighlighting();
  }

  removeRanges(textId: string) {
    this.ranges.delete(textId);
    this._updateHighlighting();
  }

  private _updateHighlighting() {
    const highlightClass1 = 'constants-highlight';
    const highlightClass2 = 'global-constants-highlight';
    const allRanges: Range[] = [];
    const allGlobalRanges: Range[] = [];

    this.ranges.forEach((ranges) => {
      allRanges.push(...ranges.constantRanges);
    });
    this.ranges.forEach((ranges) => {
      allGlobalRanges.push(...ranges.globalConstantRanges);
    });
    let constantHighlight = new Highlight(...allRanges);
    let globalConstantHighlight = new Highlight(...allGlobalRanges);


    (CSS.highlights as any).clear();
    (CSS.highlights as any).set(highlightClass1, constantHighlight);
    (CSS.highlights as any).set(highlightClass2, globalConstantHighlight);
  }
}
